.image-item {
  padding: 5px 0 5px 5px;
}
  .container {
    margin-top: 20px;
  }
  .container-with-dots {
    margin-top: 20px;
    padding-bottom: 10px;
  }
  .react-multi-carousel-dot-list {
    bottom: 0px !important;
  }
  #__next {
    overflow: scroll;
    margin-bottom: 40px;
  }
  .react-multi-carousel-dot button {
    border-color: grey !important;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  .first-carousel-container {
    margin-bottom: 50px;
  }
  #__next {
    padding: 20px;
  }